import { useField } from "recalculate-form";

const useFieldData = <T extends { name?: string }>(props: T): T => {
  try {
    const field = useField(props.name!);

    return {
      ...field.input,
      error: field.fieldState.error,
      ...props,
    };
  } catch {
    return props;
  }
};

export default useFieldData;
